/**
 *
 * You can write your JS code here, DO NOT touch the default style file
 * because it will make it harder for you to update.
 *
 */

"use strict";
$(function() {
  let password_base = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  function genPassword(length = 12) {
    let password = '';
    for (let i = 0; i < length; i++) {
      password += password_base.charAt(Math.floor(Math.random() * password_base.length));
    }
    return password;
  }

  let target = document.getElementById('password_generate');
  if (target) {
    target.addEventListener('click', function () {
      document.getElementById('user_password').setAttribute("value", genPassword());
    })
  }
})